import React from 'react';
import { Container, Paper, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png'; 

const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const LeButton = styled(Button)({
  marginTop: '16px',
  marginBottom: '6px',
  minWidth: '200px',
  backgroundColor: '#1A1E31',
  '&:hover': {
    backgroundColor: '#4a506b;',
  },
});

const BackButton = styled(Button)({
  marginTop: '16px',
  minWidth: '200px',
  backgroundColor: '#1A1E31',
  '&:hover': {
    backgroundColor: '#4a506b;',
  },
});

const Account = () => {
  const navigate = useNavigate();

  const handleChangePassword = () => {
    navigate('/account/change-password');
  };

  const handleDeleteAccount = () => {
    navigate('/account/delete-account');
  };

  const handleGoBack = () => {
    navigate(-1); // This will take the user back to the previous page
  };

  return (
    <StyledContainer>
      <StyledPaper elevation={3}>
      <div className='account-logo-container'><img className="login-logo" src={logo} alt="Logo" /> </div>
        <Typography component="h1" variant="h5" align="center" style={{marginBottom: '10px'}}>
          Account Settings
        </Typography>
        <LeButton variant="contained" color="primary" onClick={handleChangePassword}>
          Change Password
        </LeButton>
        <LeButton variant="contained" color="secondary" onClick={handleDeleteAccount}>
          Delete Account
        </LeButton>
        <BackButton variant="contained" type="button" className="go-back-button" onClick={handleGoBack}>Go Back</BackButton>
      </StyledPaper>
    </StyledContainer>
  );
};

export default Account;
