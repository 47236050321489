import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Paper, Typography, Button, Alert, TextField } from '@mui/material';
import { styled } from '@mui/system';
import { deleteAccount } from '../services/authService';

const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const DeleteButton = styled(Button)({
  marginTop: '16px',
  minWidth: '200px',
  backgroundColor: '#1A1E31',
  '&:hover': {
    backgroundColor: '#4a506b;',
  },
});

const BackButton = styled(Button)({
  marginTop: '0px',
  minWidth: '200px',
  backgroundColor: '#1A1E31',
  '&:hover': {
    backgroundColor: '#4a506b;',
  },
});

const DeleteAccount = () => {
  const [confirmation, setConfirmation] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // This will take the user back to the previous page
  };

  const handleDelete = async () => {
    if (confirmation !== 'DELETE') {
      setError('You must type DELETE to confirm');
      return;
    }
    try {
      await deleteAccount();
      setSuccess('Account deleted successfully');
      localStorage.removeItem('token'); // Remove client-side token
      setTimeout(() => {
        navigate('/login'); // Redirect to login page after a short delay
      }, 2000);
    } catch (err) {
      setError('Failed to delete account');
    }
  };

  return (
    <StyledContainer>
      <StyledPaper elevation={3} style={{ maxWidth: '370px' }}>
        <Typography component="h1" variant="h5" align="center">
          Delete Account
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        <Typography variant="body1" align="center" style={{ margin: '16px 0 6px' }}>
          Type DELETE below to confirm account deletion.
        </Typography>
        <TextField
          label="Confirmation"
          variant="outlined"
          value={confirmation}
          onChange={(e) => setConfirmation(e.target.value)}
          fullWidth
          style={{ margin: '16px 0' }}
        />
        <DeleteButton variant="contained" color="secondary" onClick={handleDelete} style={{ margin: '16px 0' }}>
          Delete Account
        </DeleteButton>
        <BackButton variant="contained" type="button" className="go-back-button" onClick={handleGoBack}>Go Back</BackButton>
      </StyledPaper>
    </StyledContainer>
  );
};

export default DeleteAccount;
