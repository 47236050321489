import axios from 'axios';

const API_URL = process.env.REACT_APP_FRONTEND_URL;

export const login = (email, password) => axios.post(`${API_URL}/login`, { email, password }, { withCredentials: true });
export const signup = (email, password, captcha) => axios.post(`${API_URL}/signup`, { email, password, captcha }, { withCredentials: true });
export const forgotPassword = (email) => axios.post(`${API_URL}/forgot-password`, { email }, { withCredentials: true });
export const resetPassword = (token, password) => axios.post(`${API_URL}/reset-password`, { token, password }, { withCredentials: true });
export const fetchCaptcha = () => axios.get(`${API_URL}/captcha`, { responseType: 'text', withCredentials: true });
export const changePassword = (currentPassword, newPassword) => axios.post(`${API_URL}/change-password`, { currentPassword, newPassword }, { withCredentials: true });
export const deleteAccount = () => axios.post(`${API_URL}/delete-account`, {}, { withCredentials: true });
export const fetchIdNumber = () => axios.get(`${API_URL}/id-number`, { withCredentials: true }); // New method
export const sendEnquiry = (email, message, captcha) => axios.post(`${API_URL}/enquire`, { email, message, captcha }, { withCredentials: true });