// Enquire.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TextInput from "../components/TextInput";
import { sendEnquiry, fetchCaptcha } from "../services/authService";
import { Container, Paper, Typography, Alert, Button } from "@mui/material";
import { styled } from "@mui/system";

const StyledContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
});
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(8),
}));
const StyledTextInput = styled(TextInput)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));
const MyButton = styled(Button)({
  marginTop: "16px",
  backgroundColor: "#1A1E31",
  "&:hover": {
    backgroundColor: "#4a506b;",
  },
});

const Enquire = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [captchaImage, setCaptchaImage] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  // Load CAPTCHA on component mount
  useEffect(() => {
    let isMounted = true;
    const loadCaptcha = async () => {
      try {
        const response = await fetchCaptcha();
        if (isMounted) {
          setCaptchaImage(response.data);
        }
      } catch (err) {
        console.error("Failed to load CAPTCHA", err);
      }
    };

    loadCaptcha();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendEnquiry(email, message, captcha);
      setSuccess("Enquiry sent successfully. We will get back to you soon.");
      setEmail("");
      setMessage("");
      setCaptcha("");
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors) {
        const serverErrors = err.response.data.errors
          .map((error) => error.msg)
          .join(", ");
        setError(`Failed to send enquiry: ${serverErrors}`);
      } else if (err.response && err.response.data) {
        setError(`Failed to send enquiry: ${JSON.stringify(err.response.data)}`);
      } else {
        setError("Failed to send enquiry");
      }
      console.error("Enquiry failed", err);
      // Optionally reload CAPTCHA on error
    }
  };

  const handleGoBack = () => {
    navigate(-1); // This will take the user back to the previous page
  };

  return (
    <StyledContainer component="main" maxWidth="xs">
      <StyledPaper elevation={3}>
        <Typography component="h1" variant="h5" align="center">
          Enquire
        </Typography>

        <form onSubmit={handleSubmit}>
          <StyledTextInput
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="enquire-email-input"
          />
          <StyledTextInput
            label="Message"
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="enquire-message-input"
          />
          <div dangerouslySetInnerHTML={{ __html: captchaImage }} />
          <StyledTextInput
            label="Captcha"
            value={captcha}
            onChange={(e) => setCaptcha(e.target.value)}
            className="enquire-captcha-input"
          />
          <MyButton
            variant="contained"
            type="submit"
            className="enquire-submit-button"
          >
            Send Enquiry
          </MyButton>
          <MyButton
            variant="contained"
            type="button"
            className="go-back-button"
            onClick={handleGoBack}
          >
            Go Back
          </MyButton>
        </form>
        {error && <Alert className="enquire-alert" severity="error">{error}</Alert>}
        {success && <Alert className="enquire-alert" severity="success">{success}</Alert>}
      </StyledPaper>
    </StyledContainer>
  );
};

export default Enquire;
