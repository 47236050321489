import React, { useState } from 'react';
import { Container, Paper, Typography, Button, TextField, Alert } from '@mui/material';
import { styled } from '@mui/system';
import { changePassword } from '../services/authService';
import { useNavigate } from 'react-router-dom';

const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const ChangeButton = styled(Button)({
    marginTop: '16px',
    marginRight: '6px',
    backgroundColor: '#1A1E31',
    '&:hover': {
      backgroundColor: '#4a506b;',
    },
  });

const BackButton = styled(Button)({
    marginTop: '16px',
    backgroundColor: '#1A1E31',
    '&:hover': {
      backgroundColor: '#4a506b;',
    },
});

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmNewPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      await changePassword(currentPassword, newPassword);
      setSuccess('Password changed successfully');
    } catch (err) {
      setError('Failed to change password');
    }
  };

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1); // This will take the user back to the previous page
  };

  return (
    <StyledContainer>
      <StyledPaper elevation={3} style={{ maxWidth: '400px' }}>
        <Typography component="h1" variant="h5" align="center">
          Change Password
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        <form onSubmit={handleSubmit}>
          <TextField
            label="Current Password"
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            style={{ margin: '8px 0' }}
            fullWidth
          />
          <TextField
            label="New Password"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            style={{ margin: '8px 0' }}
            fullWidth
          />
          <TextField
            label="Confirm New Password"
            type="password"
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            style={{ margin: '8px 0' }}
            fullWidth
          />
          <ChangeButton variant="contained" color="primary" type="submit">
            Change Password
          </ChangeButton>
          <BackButton variant="contained" type="button" className="go-back-button" onClick={handleGoBack}>Go Back</BackButton>
        </form>
      </StyledPaper>
    </StyledContainer>
  );
};

export default ChangePassword;
