// Landing.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Button } from '@mui/material';
import logo from '../assets/logo.png'; // Adjust the path if necessary
// import subwayImage from '../assets/subway.png';
// import greggsImage from '../assets/greggs.png';
// import pubImage from '../assets/pub.png';
// import shoesImage from '../assets/shoes.png';

const Landing = () => {
  // List of clients
//   const clients = [
//     { name: 'Subway', description: 'Delicious sandwiches and salads', image: subwayImage },
//     { name: 'Greggs', description: 'Fresh pastries and baked goods', image: greggsImage },
//     { name: 'Local Pubs', description: 'Enjoy a drink at your local pub', image: pubImage },
//     { name: 'Shoe Shops', description: 'Latest fashion in footwear', image: shoesImage },
//     // Add more clients as needed
//   ];

  return (
    <Container className="landing-wrapper">
      <img src={logo} alt="Logo" className="landing-logo" />
      <Typography component="h1" variant="h5" align="center" className="landing-title">
        Welcome to Our Rewards Program
      </Typography>
      <Typography variant="body1" align="center" gutterBottom className="landing-intro">
        Collect points from multiple stores and restaurants with your personal QR code and enjoy discounts with our partners.
      </Typography>
      <span className="landing-buttons">
      <Button component={Link} to="/login" className="landing-button login-link">
        Login
      </Button>
      <Button component={Link} to="/signup" className="landing-button login-link">
        Register
      </Button>
      <Button component={Link} to="/enquire" className="landing-button enquire-link">
        Enquire
      </Button>
      </span>

      {/* <div className="client-list">

        {clients.map((client, index) => (
          <div key={index} className="client-item">
            <img src={client.image} alt={client.name} className="client-image" />
            <div className="client-details">
              <Typography variant="h6" className="client-name">{client.name}</Typography>
              <Typography variant="body2" className="client-description">{client.description}</Typography>
            </div>
          </div>
        ))}
      </div> */}
    </Container>
  );
};

export default Landing;
