import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Paper, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import QRCode from 'react-qr-code';
import { fetchIdNumber } from '../services/authService'; // Import the fetchIdNumber method
import logo from '../assets/logo.png'; 

const Header = styled(Paper)({
  padding: '16px',
  // backgroundColor: '#ffeb3b', // yellow background
  width: '100%',
  textAlign: 'center',
  marginBottom: '-10px',
});

const QRCodeContainer = styled(Paper)({
  padding: '16px',
  // backgroundColor: '#c8e6c9', // light green background
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  maxWidth: '200px',
  maxHeight: '200px',
  marginBottom: '16px',
});

const Footer = styled(Paper)({
  padding: '14px 20px',
  textAlign: 'center',
  backgroundColor: '#f0f0f0', // light gray background
  width: '100%',
});

const LogoutButton = styled(Button)({
  marginTop: '16px',
  marginRight: '6px',
  backgroundColor: '#1A1E31',
  '&:hover': {
    backgroundColor: '#4a506b;',
  },
});

const AccountButton = styled(Button)({
  marginTop: '16px',
  backgroundColor: '#1A1E31',
  '&:hover': {
    backgroundColor: '#4a506b;',
  },
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2, 4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const Dashboard = () => {
  const [idNumber, setIdNumber] = useState(null);
  const navigate = useNavigate();
  const points = 0; // Example points

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchIdNumber();
        console.log('Fetched id_number:', response.data.id_number); // Debug log
        setIdNumber(response.data.id_number);
      } catch (error) {
        console.error('Failed to fetch id number', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log('idNumber state:', idNumber); // Debug log
  }, [idNumber]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  const goToAccount = () => {
    navigate('/account');
  };

  return (
    <div className='dashboard-wrapper'>
      <StyledPaper className="login-container" elevation={3}>
      <div className='dashboard-logo-container'><img className="login-logo" src={logo} alt="Logo" /> </div>
        <Header className="dashboard-header" style={{ boxShadow: 'none' }}>
          <Typography variant="h5">Your Points</Typography>
          <Typography variant="h4">{points}</Typography>
          <Typography variant="body1">ID: {idNumber}</Typography>
        </Header>
        <QRCodeContainer className="dashboard-qrcode">
          {idNumber ? <QRCode value={idNumber} /> : <Typography>Loading QR Code...</Typography>}
        </QRCodeContainer>
        <Footer className="dashboard-footer">
          <Typography variant="body2">Please scan Your QR Code at the Till</Typography>
          <Typography variant="body2">Or Provide Your ID to the Cashier</Typography>
          <AccountButton variant="contained" color="primary" onClick={goToAccount} className="dashboard-account-button">Account Settings</AccountButton>
          <LogoutButton variant="contained" color="secondary" onClick={handleLogout} className="dashboard-logout-button">Log Out</LogoutButton>
        </Footer>
      </StyledPaper> 
    </div>
  );
};

export default Dashboard;
